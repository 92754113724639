<template>
  <div class="mt-5 pt-2">
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-32 mb-3">Восстановление пароля</div>
      <a-form ref="registerForm" :model="forgotForm" :rules="rules" layout="vertical" class="mb-4">
        <a-form-item name="email">
          <a-input v-model:value="forgotForm.email" placeholder="Email" :disabled="otpSent" />
        </a-form-item>
        <a-form-item name="otp" v-if="otpSent">
          <a-input v-model:value="forgotForm.otp" placeholder="Код подтверждения" />
        </a-form-item>
        <a-button v-if="!otpSent" :loading="loading" @click="resetPassword" type="primary" class="text-center w-100" html-type="submit">
          <strong>Восстановить</strong>
        </a-button>
        <a-button v-else :loading="loading" @click="confirmOtp" type="primary" class="text-center w-100" html-type="submit">
          <strong>Подтвердить</strong>
        </a-button>
      </a-form>
      <router-link to="/auth/login" class="vb__utils__link">
        <i class="fe fe-arrow-left mr-1 align-middle" />
        Вернуться на страницу авторизации
      </router-link>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import apiClient from '@/services/axios'
import { notification } from 'ant-design-vue'

const router = useRouter()
const loading = ref(false)
const otpSent = ref(false)
const rules = {
  email: [
    {
      required: true,
      type: 'email',
      message: 'Пожалуйста ведите email адрес!',
      trigger: 'change',
    },
  ],
}
const forgotForm = reactive({
  email: '',
  otp: ''
})
const resetPassword = () => {
  if (!forgotForm.email || forgotForm.email.length < 3) {
    return false
  }
  loading.value = true
  apiClient.get(`auth/reset?email=${forgotForm.email}`).then(res => {
    if (res) {
      otpSent.value = true
      notification.success({
        message: 'Код подтверждения выслан по email',
      })
    }
  }).catch(err => {
    console.log(err)
    notification.warning({
      message: 'Не удалось отправить код подтверждение',
    })
  }).finally(() => {
    loading.value = false
  })
}
const confirmOtp = () => {
  if (forgotForm.email.length < 3 || forgotForm.otp.length < 3) {
    return false
  }
  loading.value = true
  apiClient.get(`auth/confirm?email=${forgotForm.email}&otp=${forgotForm.otp}`).then(res => {
    if (res?.data?.data?.success && res?.data?.data?.['auth_key']) {
      router.push({ name: 'reset-password', params: { key: res?.data?.data?.['auth_key'] } })
    } else {
      notification.warning({
        message: 'Не удалось подтвердить, попробуйте заново',
      })
    }
  }).catch(err => {
    console.log(err)
    // notification.warning({
    //   message: 'Не удалось отправить код подтверждение',
    // })
  }).finally(() => {
    loading.value = false
  })
}
</script>
<style lang="scss" module>
@import '@/@vb/components/Auth/style.module.scss';
</style>
